<template>
  <div>
    <v-row justify="center">
      <v-col md="6">
        <intract-smart-list
          class="mt-4"
          ref="all-circle-articles-smart-list"
          paginated
          :endpoint="circleArticlesEndpoint"
          :item-options="{ title: 'title' }"
          disable-search
          :filterFields="filterFields"
        >
          <template v-slot:list-item="{ item: article }">
            <v-card
              @click="
                $router.push({
                  name: 'ViewCircleArticle',
                  params: { articleId: article.id },
                })
              "
              class="my-4 post-card mx-3"
            >
              <v-img
                v-if="article.cover_image"
                :src="article.cover_image"
                height="180px"
                class="mt-1"
              ></v-img>

              <v-card-title class="pt-2 pb-0">
                <v-row align="center">
                  <v-col cols="8">
                    <h5 class="primary--text text-wrap">
                      {{article.title}}
                    </h5>
                  </v-col>
                  <v-col cols="2" class="text-right">
                    <v-icon color="red">mdi-cards-heart</v-icon>
                    <small class="mx-1 primary--text">{{ article.likes_count }}</small>
                  </v-col>
                  <v-col cols="2" class="text-right ma-0">
                      <v-icon color="blue">mdi-chat-processing</v-icon>
                      <small class="mx-1 primary--text">{{ article.comments_count }}</small>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-row class="mx-4 mb-2 mt-1">
                <v-chip color="primary" class="mr-1" outlined small v-for="tag in article.tags" :key="tag.id">
                  {{ tag.title }}
                </v-chip>
              </v-row>

              <v-divider class="mx-4"></v-divider>

              <v-card-subtitle class="pb-1 pt-2" style="background-color: #f5f5f5">
                {{
                  Helper.truncateText(article.description, 180)
                }}
              </v-card-subtitle>

              <!-- <v-card-text class="text-caption">
                {{ moment(article.created).format("Do MMM") }}
              </v-card-text> -->
            </v-card>
            
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";

export default {
  name: "AllCircleArticles",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: { IntractSmartList },
  data() {
    return {
      tags: [],
    }
  },
  computed: {
    circleArticlesEndpoint() {
      var url = this.Helper.addUrlParams(this.endpoints.circlesViewSet, [
        "ordering=-created",
      ]);
      // var url = this.endpoints.circlesViewSet;
      return url;
    },
    filterFields() {
      return {
        tag: {
          model: null,
          items: this.tags,
          label: "Filter by Tag",
          itemValue: "id",
          multiple: true,
          itemText: "title",
          param: "tags__id__in",
          //   dynamicParam: true
        }
      }
    },
  },
  methods: {
    async onRefresh() {
      this.$refs["all-circle-articles-smart-list"].getItems();
    },
    async getAllTags() {

      var response = await this.api.call(this.essentials, this.endpoints.circleTagViewSet)
      if (response) {
        this.tags = response;
        console.log(response);
      }
      this.subjectsLoaded = true;
    },
  },
  created() {
    this.getAllTags();
  },
};
</script>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>